import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

const LineUp = forwardRef(function LineUp(props: Props, ref: Ref<SVGSVGElement>) {
  const { size = 16, ...others } = props;

  return (
    <svg
      ref={ref}
      height={size}
      version="1.1"
      viewBox="0 0 16 16"
      width={size}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
      {...others}
    >
      <g>
        <g>
          <polygon
            points="14.76,5.67 14.34,5.67 13.92,5.67 13.5,5.67 13.07,5.67 12.65,5.67 12.23,5.67 11.8,5.67 11.38,5.67 
			10.96,5.67 10.54,5.67 10.11,5.67 10.11,6.1 9.69,6.1 9.69,6.52 9.27,6.52 9.27,6.94 9.27,7.37 8.85,7.37 8.85,7.79 8.85,8.21 
			9.27,8.21 9.69,8.21 9.69,7.79 10.11,7.79 10.54,7.79 10.96,7.79 10.96,7.37 11.38,7.37 11.8,7.37 12.23,7.37 12.23,6.94 
			12.65,6.94 13.07,6.94 13.5,6.94 13.5,6.52 13.92,6.52 14.34,6.52 14.76,6.52 15.19,6.52 15.19,6.1 15.19,5.67 		"
            style={{ fill: '#FFB9B9' }}
          />
          <rect height="0.42" style={{ fill: '#FFB9B9' }} width="0.42" x="8.42" y="8.21" />
          <polygon
            points="8,8.63 8,8.21 8,7.79 8,7.37 8,6.94 8,6.52 8,6.1 8,5.67 8,5.25 8,4.83 8,4.41 8,3.98 8,3.56 8,3.14 
			8,2.72 8,2.29 8,1.87 8,1.45 8,1.02 8,0.6 7.58,0.6 7.58,1.02 7.58,1.45 7.58,1.87 7.15,1.87 7.15,2.29 7.15,2.72 6.73,2.72 
			6.73,3.14 6.73,3.56 6.31,3.56 6.31,3.98 6.31,4.41 5.89,4.41 5.89,4.83 5.89,5.25 5.46,5.25 5.46,5.67 5.04,5.67 4.62,5.67 
			4.2,5.67 3.77,5.67 3.35,5.67 2.93,5.67 2.5,5.67 2.08,5.67 1.66,5.67 1.24,5.67 0.81,5.67 0.81,6.1 0.81,6.52 1.24,6.52 
			1.66,6.52 2.08,6.52 2.5,6.52 2.5,6.94 2.93,6.94 3.35,6.94 3.77,6.94 3.77,7.37 4.2,7.37 4.62,7.37 5.04,7.37 5.04,7.79 
			5.46,7.79 5.89,7.79 6.31,7.79 6.31,8.21 6.73,8.21 7.15,8.21 7.15,8.63 7.58,8.63 7.58,9.06 7.58,9.48 8,9.48 8.42,9.48 
			8.42,9.06 8,9.06 		"
            style={{ fill: '#FFB9B9' }}
          />
          <polygon
            points="6.31,9.9 5.89,9.9 5.46,9.9 5.46,10.33 5.04,10.33 4.62,10.33 4.62,10.75 4.62,11.17 4.2,11.17 
			4.2,11.59 4.2,12.02 4.2,12.44 3.77,12.44 3.77,12.86 3.77,13.28 3.35,13.28 3.35,13.71 3.35,14.13 2.93,14.13 2.93,14.55 
			2.93,14.98 3.35,14.98 3.35,14.55 3.77,14.55 3.77,14.13 4.2,14.13 4.2,13.71 4.62,13.71 4.62,13.28 5.04,13.28 5.04,12.86 
			5.46,12.86 5.46,12.44 5.89,12.44 5.89,12.02 6.31,12.02 6.31,11.59 6.31,11.17 6.73,11.17 6.73,10.75 6.73,10.33 7.15,10.33 
			7.15,9.9 6.73,9.9 		"
            style={{ fill: '#FFB9B9' }}
          />
          <polygon
            points="12.65,13.71 12.65,13.28 12.23,13.28 12.23,12.86 12.23,12.44 11.8,12.44 11.8,12.02 11.8,11.59 
			11.8,11.17 11.38,11.17 11.38,10.75 11.38,10.33 10.96,10.33 10.54,10.33 10.54,9.9 10.11,9.9 9.69,9.9 9.27,9.9 8.85,9.9 
			8.85,10.33 9.27,10.33 9.27,10.75 9.27,11.17 9.69,11.17 9.69,11.59 9.69,12.02 10.11,12.02 10.11,12.44 10.54,12.44 10.54,12.86 
			10.96,12.86 10.96,13.28 11.38,13.28 11.38,13.71 11.8,13.71 11.8,14.13 12.23,14.13 12.23,14.55 12.65,14.55 12.65,14.98 
			13.07,14.98 13.07,14.55 13.07,14.13 12.65,14.13 		"
            style={{ fill: '#FFB9B9' }}
          />
        </g>
        <polygon
          points="14.34,6.52 13.92,6.52 13.5,6.52 13.5,6.94 13.07,6.94 12.65,6.94 12.23,6.94 12.23,7.37 11.8,7.37 
		11.38,7.37 10.96,7.37 10.96,7.79 10.54,7.79 10.11,7.79 9.69,7.79 9.69,8.21 9.27,8.21 8.85,8.21 8.85,8.63 8.42,8.63 8.42,8.21 
		8.85,8.21 8.85,7.79 8.85,7.37 9.27,7.37 9.27,6.94 9.27,6.52 9.69,6.52 9.69,6.1 10.11,6.1 10.11,5.67 10.54,5.67 10.54,5.25 
		10.11,5.25 10.11,4.83 10.11,4.41 9.69,4.41 9.69,3.98 9.69,3.56 9.27,3.56 9.27,3.14 9.27,2.72 8.85,2.72 8.85,2.29 8.85,1.87 
		8.42,1.87 8.42,1.45 8.42,1.02 8.42,0.6 8,0.6 8,1.02 8,1.45 8,1.87 8,2.29 8,2.72 8,3.14 8,3.56 8,3.98 8,4.41 8,4.83 8,5.25 
		8,5.67 8,6.1 8,6.52 8,6.94 8,7.37 8,7.79 8,8.21 8,8.63 8,9.06 8.42,9.06 8.42,9.48 8,9.48 7.58,9.48 7.58,9.06 7.58,8.63 
		7.15,8.63 7.15,8.21 6.73,8.21 6.31,8.21 6.31,7.79 5.89,7.79 5.46,7.79 5.04,7.79 5.04,7.37 4.62,7.37 4.2,7.37 3.77,7.37 
		3.77,6.94 3.35,6.94 2.93,6.94 2.5,6.94 2.5,6.52 2.08,6.52 1.66,6.52 1.24,6.52 1.24,6.94 1.24,7.37 1.66,7.37 2.08,7.37 
		2.08,7.79 2.5,7.79 2.5,8.21 2.93,8.21 2.93,8.63 3.35,8.63 3.35,9.06 3.77,9.06 3.77,9.48 4.2,9.48 4.2,9.9 4.62,9.9 4.62,10.33 
		5.04,10.33 5.46,10.33 5.46,9.9 5.89,9.9 6.31,9.9 6.73,9.9 7.15,9.9 7.15,10.33 6.73,10.33 6.73,10.75 6.73,11.17 6.31,11.17 
		6.31,11.59 6.31,12.02 5.89,12.02 5.89,12.44 5.46,12.44 5.46,12.86 5.04,12.86 5.04,13.28 4.62,13.28 4.62,13.71 4.2,13.71 
		4.2,14.13 3.77,14.13 3.77,14.55 3.35,14.55 3.35,14.98 2.93,14.98 2.93,15.4 3.35,15.4 3.77,15.4 3.77,14.98 4.2,14.98 
		4.62,14.98 4.62,14.55 5.04,14.55 5.04,14.13 5.46,14.13 5.89,14.13 5.89,13.71 6.31,13.71 6.31,13.28 6.73,13.28 7.15,13.28 
		7.15,12.86 7.58,12.86 8,12.86 8.42,12.86 8.85,12.86 8.85,13.28 9.27,13.28 9.69,13.28 9.69,13.71 10.11,13.71 10.11,14.13 
		10.54,14.13 10.96,14.13 10.96,14.55 11.38,14.55 11.38,14.98 11.8,14.98 12.23,14.98 12.23,15.4 12.65,15.4 13.07,15.4 
		13.07,14.98 12.65,14.98 12.65,14.55 12.23,14.55 12.23,14.13 11.8,14.13 11.8,13.71 11.38,13.71 11.38,13.28 10.96,13.28 
		10.96,12.86 10.54,12.86 10.54,12.44 10.11,12.44 10.11,12.02 9.69,12.02 9.69,11.59 9.69,11.17 9.27,11.17 9.27,10.75 9.27,10.33 
		8.85,10.33 8.85,9.9 9.27,9.9 9.69,9.9 10.11,9.9 10.54,9.9 10.54,10.33 10.96,10.33 11.38,10.33 11.38,9.9 11.8,9.9 11.8,9.48 
		12.23,9.48 12.23,9.06 12.65,9.06 12.65,8.63 13.07,8.63 13.07,8.21 13.5,8.21 13.5,7.79 13.92,7.79 13.92,7.37 14.34,7.37 
		14.76,7.37 14.76,6.94 14.76,6.52 	"
          style={{ fill: '#FF9494' }}
        />
        <g>
          <polygon points="8.42,0.6 8.42,0.18 8,0.18 7.58,0.18 7.58,0.6 8,0.6 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="8.42,1.45 8.42,1.87 8.85,1.87 8.85,1.45 8.85,1.02 8.85,0.6 8.42,0.6 8.42,1.02 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="7.58,1.45 7.58,1.02 7.58,0.6 7.15,0.6 7.15,1.02 7.15,1.45 7.15,1.87 7.58,1.87 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="7.15,2.29 7.15,1.87 6.73,1.87 6.73,2.29 6.73,2.72 7.15,2.72 		" style={{ fill: '#FF5F5F' }} />
          <polygon points="8.85,2.72 9.27,2.72 9.27,2.29 9.27,1.87 8.85,1.87 8.85,2.29 		" style={{ fill: '#FF5F5F' }} />
          <polygon points="6.73,3.14 6.73,2.72 6.31,2.72 6.31,3.14 6.31,3.56 6.73,3.56 		" style={{ fill: '#FF5F5F' }} />
          <polygon points="9.27,3.56 9.69,3.56 9.69,3.14 9.69,2.72 9.27,2.72 9.27,3.14 		" style={{ fill: '#FF5F5F' }} />
          <polygon points="6.31,3.98 6.31,3.56 5.89,3.56 5.89,3.98 5.89,4.41 6.31,4.41 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="9.69,4.41 10.11,4.41 10.11,3.98 10.11,3.56 9.69,3.56 9.69,3.98 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="5.89,4.83 5.89,4.41 5.46,4.41 5.46,4.83 5.46,5.25 5.89,5.25 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="10.11,5.25 10.54,5.25 10.54,4.83 10.54,4.41 10.11,4.41 10.11,4.83 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="1.66,5.67 2.08,5.67 2.5,5.67 2.93,5.67 3.35,5.67 3.77,5.67 4.2,5.67 4.62,5.67 5.04,5.67 
			5.46,5.67 5.46,5.25 5.04,5.25 4.62,5.25 4.2,5.25 3.77,5.25 3.35,5.25 2.93,5.25 2.5,5.25 2.08,5.25 1.66,5.25 1.24,5.25 
			0.81,5.25 0.81,5.67 1.24,5.67 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="10.96,5.67 11.38,5.67 11.8,5.67 12.23,5.67 12.65,5.67 13.07,5.67 13.5,5.67 13.92,5.67 14.34,5.67 
			14.76,5.67 15.19,5.67 15.19,5.25 14.76,5.25 14.34,5.25 13.92,5.25 13.5,5.25 13.07,5.25 12.65,5.25 12.23,5.25 11.8,5.25 
			11.38,5.25 10.96,5.25 10.54,5.25 10.54,5.67 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="0.81,5.67 0.39,5.67 0.39,6.1 0.39,6.52 0.81,6.52 0.81,6.1 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="15.19,5.67 15.19,6.1 15.19,6.52 15.61,6.52 15.61,6.1 15.61,5.67 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="1.24,6.52 0.81,6.52 0.81,6.94 0.81,7.37 1.24,7.37 1.24,6.94 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="14.76,6.94 14.76,7.37 15.19,7.37 15.19,6.94 15.19,6.52 14.76,6.52 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="1.66,7.37 1.24,7.37 1.24,7.79 1.66,7.79 2.08,7.79 2.08,7.37 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="13.92,7.37 13.92,7.79 14.34,7.79 14.76,7.79 14.76,7.37 14.34,7.37 		"
            style={{ fill: '#FF5F5F' }}
          />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="2.08" y="7.79" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="13.5" y="7.79" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="2.5" y="8.21" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="13.07" y="8.21" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="2.93" y="8.63" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="12.65" y="8.63" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="3.35" y="9.06" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="12.23" y="9.06" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="3.77" y="9.48" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="11.8" y="9.48" />
          <polygon
            points="11.38,10.33 11.38,10.75 11.38,11.17 11.8,11.17 11.8,10.75 11.8,10.33 11.8,9.9 11.38,9.9 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="4.62,10.75 4.62,10.33 4.62,9.9 4.2,9.9 4.2,10.33 4.2,10.75 4.2,11.17 4.62,11.17 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="11.8,12.02 11.8,12.44 12.23,12.44 12.23,12.02 12.23,11.59 12.23,11.17 11.8,11.17 11.8,11.59 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="4.2,12.02 4.2,11.59 4.2,11.17 3.77,11.17 3.77,11.59 3.77,12.02 3.77,12.44 4.2,12.44 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="3.77,12.86 3.77,12.44 3.35,12.44 3.35,12.86 3.35,13.28 3.77,13.28 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="8.42,12.86 8,12.86 7.58,12.86 7.15,12.86 7.15,13.28 7.58,13.28 8,13.28 8.42,13.28 8.85,13.28 
			8.85,12.86 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="12.23,13.28 12.65,13.28 12.65,12.86 12.65,12.44 12.23,12.44 12.23,12.86 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="6.31,13.28 6.31,13.71 6.73,13.71 7.15,13.71 7.15,13.28 6.73,13.28 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="9.27,13.28 8.85,13.28 8.85,13.71 9.27,13.71 9.69,13.71 9.69,13.28 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="3.35,13.71 3.35,13.28 2.93,13.28 2.93,13.71 2.93,14.13 3.35,14.13 		"
            style={{ fill: '#FF5F5F' }}
          />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="5.89" y="13.71" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="9.69" y="13.71" />
          <polygon
            points="12.65,14.13 13.07,14.13 13.07,13.71 13.07,13.28 12.65,13.28 12.65,13.71 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="5.04,14.13 5.04,14.55 5.46,14.55 5.89,14.55 5.89,14.13 5.46,14.13 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="10.54,14.13 10.11,14.13 10.11,14.55 10.54,14.55 10.96,14.55 10.96,14.13 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="2.93,14.55 2.93,14.13 2.5,14.13 2.5,14.55 2.5,14.98 2.5,15.4 2.93,15.4 2.93,14.98 		"
            style={{ fill: '#FF5F5F' }}
          />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="4.62" y="14.55" />
          <rect height="0.42" style={{ fill: '#FF5F5F' }} width="0.42" x="10.96" y="14.55" />
          <polygon
            points="13.07,14.98 13.07,15.4 13.5,15.4 13.5,14.98 13.5,14.55 13.5,14.13 13.07,14.13 13.07,14.55 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon points="3.77,14.98 3.77,15.4 4.2,15.4 4.62,15.4 4.62,14.98 4.2,14.98 		" style={{ fill: '#FF5F5F' }} />
          <polygon
            points="11.8,14.98 11.38,14.98 11.38,15.4 11.8,15.4 12.23,15.4 12.23,14.98 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="2.93,15.4 2.93,15.82 3.35,15.82 3.77,15.82 3.77,15.4 3.35,15.4 		"
            style={{ fill: '#FF5F5F' }}
          />
          <polygon
            points="12.23,15.4 12.23,15.82 12.65,15.82 13.07,15.82 13.07,15.4 12.65,15.4 		"
            style={{ fill: '#FF5F5F' }}
          />
        </g>
      </g>
    </svg>
  );
});

export default LineUp;
