import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getBrandsReceiptCustomizationRequest(storeUuid, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/brands/config/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function getBrandReceiptCustomizationRequest({ storeUuid, brandUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/brands/${brandUuid}/config/?store_uuid=${storeUuid}`, {
    method: 'GET',
    ...options,
  });
}

export function saveBrandReceiptCustomizationRequest(
  {
    storeUuid,
    brandUuid,
    footerText,
    headerText,
    printerLogo,
    isPromotionalImgEnabled,
    promotionalImg = null,
    isPromotionalQrEnabled,
    promotionalDescription = '',
    promotionalQr = '',
    sections,
  },
  options = {},
) {
  const headers = { ...options.headers };
  delete headers['Content-Type'];

  options = {
    ...options,
    headers: {
      ...headers,
    },
  };

  const formData = new FormData();

  const configValues = {
    footerText,
    headerText,
    printer_logo: printerLogo,
    isPromotionalImgEnabled,
    promotionalImg,
    isPromotionalQrEnabled,
    promotionalQr,
    promotionalDescription,
    sections,
  };

  Object.keys(configValues).forEach((key) => {
    let value = configValues[key];

    if (value === null || value === undefined) {
      return;
    }

    if (typeof value === 'object' && key !== 'printer_logo' && key !== 'promotionalImg') {
      value = JSON.stringify(value);
    }
    formData.append(key, value);
  });

  return request(`${API_BASE_URL}/v1/stores/brands/${brandUuid}/config/?store_uuid=${storeUuid}`, {
    method: 'PUT',
    body: formData,
    ...options,
  });
}
