import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import {
  getBrandsReceiptCustomization,
  getBrandReceiptCustomization,
  updateBrandReceiptCustomization,
} from './actions';

import {
  selectBrandsReceiptCustomization,
  selectBrandsReceiptCustomizationFetching,
  selectBrandsReceiptCustomizationFetched,
  selectBrandsReceiptCustomizationError,
  selectBrandReceiptCustomization,
  selectBrandReceiptCustomizationFetching,
  selectBrandReceiptCustomizationFetched,
  selectBrandReceiptCustomizationError,
  selectUpdateBrandReceiptCustomization,
  selectUpdateBrandReceiptCustomizationFetching,
  selectUpdateBrandReceiptCustomizationFetched,
  selectUpdateBrandReceiptCustomizationError,
} from './selectors';

export function withReceiptCustomizationHoc(Component) {
  function withReceiptCustomization(props) {
    return <Component {...props} />;
  }

  withReceiptCustomization.displayName = `withReceiptCustomization(${getDisplayName(Component)})`;

  return withReceiptCustomization;
}

const mapStateToProps = createStructuredSelector({
  brandsReceiptCustomizationList: selectBrandsReceiptCustomization,
  brandsReceiptCustomizationFetching: selectBrandsReceiptCustomizationFetching,
  brandsReceiptCustomizationFetched: selectBrandsReceiptCustomizationFetched,
  brandsReceiptCustomizationError: selectBrandsReceiptCustomizationError,
  brandConfigObject: selectBrandReceiptCustomization,
  brandConfigFetching: selectBrandReceiptCustomizationFetching,
  brandConfigFetched: selectBrandReceiptCustomizationFetched,
  brandConfigError: selectBrandReceiptCustomizationError,
  updateBrandConfigObject: selectUpdateBrandReceiptCustomization,
  updateBrandConfigFetching: selectUpdateBrandReceiptCustomizationFetching,
  updateBrandConfigFetched: selectUpdateBrandReceiptCustomizationFetched,
  updateBrandConfigError: selectUpdateBrandReceiptCustomizationError,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadBrandsReceiptCustomization: (storeUuid) => dispatch(getBrandsReceiptCustomization(storeUuid)),
    loadBrandConfig: ({ storeUuid, brandUuid }) => dispatch(getBrandReceiptCustomization({ storeUuid, brandUuid })),
    updateBrandConfig: ({
      storeUuid,
      brandUuid,
      footerText,
      headerText,
      printerLogo,
      isPromotionalImgEnabled,
      promotionalImg,
      isPromotionalQrEnabled,
      promotionalDescription,
      promotionalQr,
      sections,
    }) =>
      dispatch(
        updateBrandReceiptCustomization({
          storeUuid,
          brandUuid,
          footerText,
          headerText,
          printerLogo,
          isPromotionalImgEnabled,
          promotionalImg,
          isPromotionalQrEnabled,
          promotionalDescription,
          promotionalQr,
          sections,
        }),
      ),
    resetBrandsReceiptCustomization: () => dispatch(getBrandsReceiptCustomization.reset()),
    resetBrandConfig: () => dispatch(getBrandReceiptCustomization.reset()),
    resetUpdateBrandConfig: () => dispatch(updateBrandReceiptCustomization.reset()),
  };
}

export const withReceiptCustomizationState = connect(mapStateToProps, mapDispatchToProps);
export default compose(withReceiptCustomizationState, withReceiptCustomizationHoc);
