import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  logSmall: {
    width: 28,
  },

  item: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    padding: 0,
    margin: 0,
  },

  navLink: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    padding: '.5rem 1rem .5rem 1.25rem',
    width: '100%',
    fontWeight: '500',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: theme.typography.pxToRem(4),
    display: 'flex',
    gap: '.5rem',
    justifyContent: 'center',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },

  navLinkExtenal: {
    justifyContent: 'flex-start',
  },

  navButton: {
    color: '#FFFFFF',
    textDecoration: 'none',
    fontSize: theme.typography.pxToRem(18),
    width: '100%',
    fontWeight: 'normal',
  },

  navLinkLabel: {
    width: '100%',
    margin: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  navLinkActive: {
    fontWeight: theme.typography.fontWeightMedium,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftWidth: theme.typography.pxToRem(4),
    background: theme.palette.darkGrey.selected,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },

  navLinkEndIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  navLinkStartIcon: {
    display: 'flex',
    alignItems: 'center',

    '& > :not(:last-child)': {
      height: 24,
    },
  },

  navLinkLabelComplementIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },

  spacer: {
    flex: '1 1 auto',
  },

  line: {
    width: 30,
    height: 'auto',
  },

  disabled: {
    pointerEvents: 'none',
  },

  menuLink: {
    color: theme.palette.common.white,
    textDecoration: 'none',
  },

  navLiButton: {
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  buttonCard: {
    backgroundColor: (props) => (props.isOpenMenu ? theme.palette.background.default : theme.palette.primary.main),
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '8px 12px',
    lineHeight: 1.5,
    height: theme.typography.pxToRem(42),
    width: '100%',
    maxWidth: '14rem',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
    margin: (props) => (props.isOpenMenu ? '0 0 2rem 0.5rem' : '0 0 2rem 0rem'),

    '&:hover': {
      border: (props) => `1px solid ${props.isOpenMenu ? '#616161' : theme.palette.primary.main}`,
      backgroundColor: (props) => {
        if (props.isTestEnv) {
          return 'rgba(0,0,0, .2)';
        }
        if (props.isOpenMenu) {
          return '#3C3C3C';
        }
        return theme.palette.primary.main;
      },
      boxShadow: 'none',
      color: theme.palette.background.default,
    },

    '&:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  buttonCardActive: {
    '& > button': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  buttonCardCollapseMenu: {
    padding: 0,
    minWidth: '50px',
    border: 'none',
  },

  startIcon: {
    marginRight: theme.spacing(1),
  },
  startIconV2: {
    marginRight: 0,
  },
  collapseMenu: {
    paddingLeft: '0.9rem',
  },
  link: {
    textDecoration: 'unset',
    color: theme.palette.common.white,
    '&:hover': {
      color: `${theme.palette.secondary.main}!important`,
    },
  },
}));

export default useStyles;
