import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  colorInherit?: string;
  size?: string | number;
}

const ErrorCircle = forwardRef(function ErrorCircle(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FADCDB', colorInherit = '#DF130E', size = 72, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <circle cx="32" cy="32" fill={color} r="32" />
      <path
        clipRule="evenodd"
        d="M40.4177 42.6663L21.3335 23.5821L23.5826 21.333L42.6668 40.4172L40.4177 42.6663Z"
        fill={colorInherit}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M40.4177 21.3327L21.3335 40.4169L23.5826 42.666L42.6668 23.5818L40.4177 21.3327Z"
        fill={colorInherit}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ErrorCircle;
