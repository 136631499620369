import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import IconError from '@experimental-components/IconsComponents/ErrorCircle';

import useStyles from './styles';

function PageError({ id, labelAction, title = '', maxWidth = 600, message, onAction, widthButton = 212, ...others }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Container className={classes.root} data-testid={id} id={id} maxWidth="sm" {...others}>
      <IconError color={theme.palette.error.main} colorInherit={theme.palette.error.light} />

      <Box maxWidth={maxWidth} mb={4} mt={3} width="100%">
        {title && (
          <Typography align="center" fontSize={18} fontWeight={500}>
            {title}
          </Typography>
        )}

        <Typography
          align="center"
          color={theme.palette.text.secondary}
          mt={1}
          style={{ whiteSpace: 'pre-line' }}
          variant="body1"
        >
          {message}
        </Typography>
      </Box>

      {onAction && (
        <Box maxWidth={widthButton} width={widthButton}>
          <ButtonPrimary data-testid={`btn-${id}`} fullWidth onClick={onAction}>
            {labelAction}
          </ButtonPrimary>
        </Box>
      )}
    </Container>
  );
}

PageError.propTypes = {
  id: PropTypes.string,
  labelAction: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.number,
  message: PropTypes.string,
  onAction: PropTypes.func,
  variant: PropTypes.string,
};

export default PageError;
